export const ArchiveIcon = ({
  className,
}: {
  className?: string;
}): JSX.Element => {
  return (
    <svg
      className={className || 'w-3.5 h-3.5 fill-current'}
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_7648_19275)'>
        <path
          d='M0.886719 3.85224V12.7445C0.886719 13.0513 1.13499 13.3001 1.44235 13.3001H12.5571C12.8639 13.3001 13.1127 13.0513 13.1127 12.7445V3.85224C13.1127 3.69856 13.0504 3.55967 12.9497 3.45904L10.3537 0.863017C10.2493 0.758564 10.1082 0.700043 9.96053 0.700043H4.03895C3.89129 0.700043 3.7502 0.758559 3.64629 0.863017L1.04971 3.45904C0.949082 3.55966 0.886719 3.69856 0.886719 3.85224ZM4.26856 1.81132H9.7308L11.2155 3.29602L2.78372 3.29657L4.26856 1.81132ZM1.9979 4.4079H12.0013V12.1883L1.9979 12.1888V4.4079Z'
          fill='currentColor'
        />
        <path
          d='M7.55528 8.85388V6.07516H6.44403V8.85388L5.05442 7.46482L4.26855 8.25013L6.99968 10.9813L9.73079 8.25013L8.94493 7.46482L7.55528 8.85388Z'
          fill='currentColor'
        />
      </g>
      <defs>
        <clipPath id='clip0_7648_19275'>
          <rect width='14' height='14' fill='currentColor' />
        </clipPath>
      </defs>
    </svg>
  );
};
